import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { EraDto } from "src/app/services/generatedApi";
import { buildMainTableData } from "./buildMainTableData";

export function generateMainTable(doc: jsPDF, eraDto: EraDto) {
  doc.setFontSize(10);
  doc.text(
    "Step 1: Identify risk factors, score each body part, and calculate individual body part scores:",
    10,
    doc.lastAutoTable.finalY + 10,
  );

  const { mainTableHead, mainTableBody } = buildMainTableData(eraDto);

  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 15,
    head: mainTableHead,
    body: mainTableBody,
    theme: "grid",
    columnStyles: {
      0: { fontSize: 8, fontStyle: "bold", cellWidth: 16 },
      2: { halign: "center" },
      4: { halign: "center" },
      6: { halign: "center" },
      7: { halign: "center" },
      9: { halign: "center" },
      10: { halign: "center" },
      12: { halign: "center" },
      13: { halign: "center" },
    },
    styles: {
      cellPadding: 1,
      fontSize: 7,
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      valign: "middle",
    },
    headStyles: {
      fillColor: [220, 220, 220],
      fontSize: 8,
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
    },
  });

  return doc;
}
