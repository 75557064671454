import { EraDto } from "src/app/services/generatedApi";
import { boolToScore } from "./utils";

export function buildStaticRow(eraDto: EraDto) {
  return [
    { content: "Static\nExertion" },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.back.staticExertion.over10Sec) },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.neck.staticExertion.over10Sec) },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.leftShoulder.staticExertion.over10Sec) },
    { content: boolToScore(eraDto.rightShoulder.staticExertion.over10Sec) },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.leftElbow.staticExertion.over10Sec) },
    { content: boolToScore(eraDto.rightElbow.staticExertion.over10Sec) },
    { content: ">10 sec" },
    { content: boolToScore(eraDto.leftWrist.staticExertion.over10Sec) },
    { content: boolToScore(eraDto.rightWrist.staticExertion.over10Sec) },
  ];
}
