import { EraDto } from "src/app/services/generatedApi";
import { boolToScore } from "./utils";

export function buildPostureRow(eraDto: EraDto) {
  return [
    { content: "Posture" },
    { content: "Flexed>20°\nExtended\nSideways>20°\nTwisted>20°" },
    {
      content: [
        boolToScore(eraDto.back.posture.flexedOver20),
        boolToScore(eraDto.back.posture.extended),
        boolToScore(eraDto.back.posture.sidewaysOver20),
        boolToScore(eraDto.back.posture.twistedOver20),
      ].join("\n"),
    },
    { content: "Flexed>30°\nExtended\nSideways\nTwisted" },
    {
      content: [
        boolToScore(eraDto.neck.posture.flexedOver30),
        boolToScore(eraDto.neck.posture.extended),
        boolToScore(eraDto.neck.posture.sideways),
        boolToScore(eraDto.neck.posture.twisted),
      ].join("\n"),
    },
    { content: "Flexed>45°\nExtended\nAbducted>45°" },
    {
      content: [
        boolToScore(eraDto.leftShoulder.posture.flexedOver45),
        boolToScore(eraDto.leftShoulder.posture.extended),
        boolToScore(eraDto.leftShoulder.posture.abductedOver45),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightShoulder.posture.flexedOver45),
        boolToScore(eraDto.rightShoulder.posture.extended),
        boolToScore(eraDto.rightShoulder.posture.abductedOver45),
      ].join("\n"),
    },
    { content: "Flexed<60°\nFlexed>100°\nForearm Rotated" },
    {
      content: [
        boolToScore(eraDto.leftElbow.posture.flexedUnder60),
        boolToScore(eraDto.leftElbow.posture.flexedOver100),
        boolToScore(eraDto.leftElbow.posture.forearmRotated),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightElbow.posture.flexedUnder60),
        boolToScore(eraDto.rightElbow.posture.flexedOver100),
        boolToScore(eraDto.rightElbow.posture.forearmRotated),
      ].join("\n"),
    },
    {
      content:
        "Flexed>45°\nExtended>45°\nRadial Deviated>15°\nUlnar Deviated>20°",
    },
    {
      content: [
        boolToScore(eraDto.leftWrist.posture.flexedOver45),
        boolToScore(eraDto.leftWrist.posture.extendedOver45),
        boolToScore(eraDto.leftWrist.posture.radialDeviatedOver15),
        boolToScore(eraDto.leftWrist.posture.ulnarDeviatedOver20),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightWrist.posture.flexedOver45),
        boolToScore(eraDto.rightWrist.posture.extendedOver45),
        boolToScore(eraDto.rightWrist.posture.radialDeviatedOver15),
        boolToScore(eraDto.rightWrist.posture.ulnarDeviatedOver20),
      ].join("\n"),
    },
  ];
}
