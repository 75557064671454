import { FC } from "react";
import { useAppSelector } from "src/app/hooks";
import { Navigate, Outlet } from "react-router-dom";

const AdminPrivateRoute: FC = () => {
  const { auth } = useAppSelector((state) => state);

  return auth?.isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminPrivateRoute;
