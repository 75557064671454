// Companies.tsx
import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Template from "../../components/templates/Template";
import { CompanyRo } from "src/app/services/generatedApi";
import { useCompanyManagement } from "src/components/hook/useCompanyManagement";
import { CompanyTable } from "src/components/organisms/company/CompanyTable";
import { AddCompanyDialog } from "src/components/organisms/company/AddCompanyDialog";
import { EditCompanyDialog } from "src/components/organisms/company/EditCompanyDialog";

const Companies: FC = () => {
  const {
    companies,
    selectedCompany,
    setSelectedCompany,
    isAddDialogOpen,
    setIsAddDialogOpen,
    isEditDialogOpen,
    setIsEditDialogOpen,
    refetch,
    resetDialogs,
  } = useCompanyManagement();

  const handleEditClick = (company: CompanyRo) => {
    setSelectedCompany(company);
    setIsEditDialogOpen(true);
  };

  return (
    <Template>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">Companies</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsAddDialogOpen(true)}
        >
          Add Company
        </Button>
      </Box>

      <CompanyTable
        companies={companies}
        onEditClick={handleEditClick}
        onDeleteSuccess={refetch}
      />

      <AddCompanyDialog
        open={isAddDialogOpen}
        onClose={resetDialogs}
        onSuccess={refetch}
      />

      <EditCompanyDialog
        open={isEditDialogOpen}
        onClose={resetDialogs}
        onSuccess={refetch}
        company={selectedCompany}
      />
    </Template>
  );
};

export default Companies;
