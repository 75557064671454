import { EraDto } from "src/app/services/generatedApi";
import { boolToScore } from "./utils";

export function buildRepetitionRow(eraDto: EraDto) {
  return [
    { content: "Repetition" },
    { content: ">2/min" },
    { content: boolToScore(eraDto.back.repetition.over2PerMin, 2) },
    { content: ">3/min" },
    { content: boolToScore(eraDto.neck.repetition.over3PerMin, 2) },
    { content: ">2.5/min" },
    {
      content: boolToScore(eraDto.leftShoulder.repetition.over2Point5PerMin, 2),
    },
    {
      content: boolToScore(
        eraDto.rightShoulder.repetition.over2Point5PerMin,
        2,
      ),
    },
    { content: ">10/min" },
    { content: boolToScore(eraDto.leftElbow.repetition.over10PerMin, 2) },
    { content: boolToScore(eraDto.rightElbow.repetition.over10PerMin, 2) },
    { content: ">15/min" },
    { content: boolToScore(eraDto.leftWrist.repetition.over15PerMin, 2) },
    { content: boolToScore(eraDto.rightWrist.repetition.over15PerMin, 2) },
  ];
}
