export function buildMainTableHead() {
  return [
    [
      { content: "" },
      { content: "Back", colSpan: 2 },
      { content: "Neck", colSpan: 2 },
      { content: "Shoulder" },
      { content: "L" },
      { content: "R" },
      { content: "Elbow" },
      { content: "L" },
      { content: "R" },
      { content: "Wrist" },
      { content: "L" },
      { content: "R" },
    ],
  ];
}
