import { FC, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  LinearProgress,
  Stack,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  AlertTitle,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Download, ExpandMore } from "@mui/icons-material";

type ScoreBoardProps = {
  scores: {
    backScore: number;
    neckScore: number;
    leftShoulderScore: number;
    rightShoulderScore: number;
    leftElbowScore: number;
    rightElbowScore: number;
    leftWristScore: number;
    rightWristScore: number;
    acceleratorScore: number;
    totalScore: number;
    riskLevel: string;
  };
  onDownloadReport: () => void;
};

const getScoreColor = (score: number, max: number): string => {
  const ratio = score / max;
  if (ratio <= 0.25) return "#4caf50";
  if (ratio <= 0.5) return "#ff9800";
  if (ratio <= 0.75) return "#f57c00";
  return "#d32f2f";
};

const getRiskIcon = (level: string) => {
  switch (level.toLowerCase()) {
    case "low":
      return <CheckCircleIcon color="success" />;
    case "moderate":
      return <WarningIcon color="warning" />;
    case "high":
    case "very high":
      return <ErrorIcon color="error" />;
    default:
      return null;
  }
};

const SingleScoreItem: FC<{ label: string; score: number; max: number }> = ({
  label,
  score,
  max,
}) => (
  <Box>
    <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
      {label}
    </Typography>
    <Stack direction="row" alignItems="center" spacing={0.1}>
      <LinearProgress
        variant="determinate"
        value={Math.min((score / max) * 100, 100)}
        sx={{
          height: 4,
          borderRadius: 2,
          bgcolor: "grey.200",
          "& .MuiLinearProgress-bar": {
            bgcolor: getScoreColor(score, max),
          },
          flexGrow: 1,
        }}
      />
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ width: 60, textAlign: "end" }}
      >
        {score} / {max}
      </Typography>
    </Stack>
  </Box>
);

const PairedScoreItem: FC<{
  label: string;
  leftScore: number;
  rightScore: number;
  max: number;
}> = ({ label, leftScore, rightScore, max }) => (
  <Box>
    <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
      {label}
    </Typography>
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.1}
        sx={{ height: 10 }}
      >
        <LinearProgress
          variant="determinate"
          value={Math.min((leftScore / max) * 100, 100)}
          sx={{
            height: 4,
            borderRadius: 2,
            bgcolor: "grey.200",
            "& .MuiLinearProgress-bar": {
              bgcolor: getScoreColor(leftScore, max),
            },
            flexGrow: 1,
          }}
        />
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ width: 60, textAlign: "end" }}
        >
          (L) {leftScore} / {max}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.1}>
        <LinearProgress
          variant="determinate"
          value={Math.min((rightScore / max) * 100, 100)}
          sx={{
            height: 4,
            borderRadius: 2,
            bgcolor: "grey.200",
            "& .MuiLinearProgress-bar": {
              bgcolor: getScoreColor(rightScore, max),
            },
            flexGrow: 1,
          }}
        />
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ width: 60, textAlign: "end" }}
        >
          (R) {rightScore} / {max}
        </Typography>
      </Stack>
    </Stack>
  </Box>
);

export const ScoreBoard: FC<ScoreBoardProps> = ({
  scores,
  onDownloadReport,
}) => {
  const [expanded, setExpanded] = useState(false);

  const maxScores = {
    back: 11,
    neck: 9,
    shoulders: 10,
    elbows: 9,
    wrists: 11,
    accelerators: 3,
  };

  return (
    <Grid item xs={12}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{ boxShadow: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            borderBottom: expanded ? 1 : 0,
            borderColor: "divider",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", pr: 2 }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              {getRiskIcon(scores.riskLevel)}
              <Box>
                <Typography variant="h6">{scores.riskLevel} Risk</Typography>
                <Typography variant="body2" color="text.secondary">
                  Total Score: {scores.totalScore}
                </Typography>
              </Box>
            </Stack>
            <Button
              startIcon={<Download />}
              onClick={(e) => {
                e.stopPropagation();
                onDownloadReport();
              }}
            >
              Download Report
            </Button>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={6}>
              <SingleScoreItem
                label="Back"
                score={scores.backScore}
                max={maxScores.back}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SingleScoreItem
                label="Neck"
                score={scores.neckScore}
                max={maxScores.neck}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PairedScoreItem
                label="Shoulders"
                leftScore={scores.leftShoulderScore}
                rightScore={scores.rightShoulderScore}
                max={maxScores.shoulders}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PairedScoreItem
                label="Elbows"
                leftScore={scores.leftElbowScore}
                rightScore={scores.rightElbowScore}
                max={maxScores.elbows}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PairedScoreItem
                label="Wrists"
                leftScore={scores.leftWristScore}
                rightScore={scores.rightWristScore}
                max={maxScores.wrists}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SingleScoreItem
                label="Accelerators"
                score={scores.acceleratorScore}
                max={maxScores.accelerators}
              />
            </Grid>
          </Grid>

          {scores.riskLevel !== "Low" && (
            <Alert
              severity={
                scores.riskLevel === "Moderate"
                  ? "warning"
                  : scores.riskLevel === "High"
                    ? "error"
                    : "error"
              }
              sx={{ mt: 2 }}
            >
              <AlertTitle>Recommendation</AlertTitle>
              {scores.riskLevel === "Moderate" &&
                "Further investigation and action recommended"}
              {scores.riskLevel === "High" && "Action strongly recommended"}
              {scores.riskLevel === "Very High" &&
                "Immediate action strongly recommended"}
            </Alert>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ScoreBoard;
