import { useState } from "react";
import {
  useCompanyControllerFindAllQuery,
  CompanyRo,
} from "src/app/services/generatedApi";

export const useCompanyManagement = () => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyRo | null>(
    null,
  );

  const { data: companies = [], refetch } = useCompanyControllerFindAllQuery();

  const resetDialogs = () => {
    setIsAddDialogOpen(false);
    setIsEditDialogOpen(false);
    setSelectedCompany(null);
  };

  return {
    companies,
    selectedCompany,
    setSelectedCompany,
    isAddDialogOpen,
    setIsAddDialogOpen,
    isEditDialogOpen,
    setIsEditDialogOpen,
    refetch,
    resetDialogs,
  };
};
