import { useCallback } from "react";
import { twoDecimalPlaces } from "src/utils/chartsUtils";
import {
  extractRulaData,
  getRulaResult,
  getRulaScore,
} from "src/app/logic/assessment/rula/Rula";
import { getRulaQuestions } from "src/app/logic/assessment/rula/RulaQuestions";
import {
  extractRebaData,
  getRebaResult,
  getRebaScore,
} from "src/app/logic/assessment/reba/Reba";
import { getRebaQuestions } from "src/app/logic/assessment/reba/RebaQuestions";
import { ResultRo } from "src/app/services/generatedApi";
import { Angle3DResultRo } from "src/types/pose";
import { calculateEraData } from "src/app/logic/assessment/era/calculate";

export interface ChartDataPoint {
  angle: number;
  seconds: number;
}

interface UseAssessmentLogicProps {
  selectedMethod?: "RULA" | "REBA" | "MMH" | "ERA";
  selectedAngles: ResultRo[];
  selectedAngles3D?: Angle3DResultRo[];
  fps: number;
  isSited: boolean;
  frameIndex?: number;
  setAssessmentDataDto: (data: any) => void;
  setAssessmentPageIndex: (index: number) => void;
  setTargetId: (id: number | undefined) => void;
}

export const useAssessmentLogic = ({
  selectedMethod,
  selectedAngles,
  selectedAngles3D,
  fps,
  isSited,
  frameIndex,
  setAssessmentDataDto,
  setAssessmentPageIndex,
  setTargetId,
}: UseAssessmentLogicProps) => {
  const getChartData = useCallback((): ChartDataPoint[] => {
    if (!selectedAngles) return [];

    return selectedAngles.map((frameAngles, index) => ({
      angle:
        selectedMethod === "RULA"
          ? getRulaScore(
              getRulaResult(
                extractRulaData(
                  getRulaQuestions(),
                  frameAngles,
                  selectedAngles3D?.[index],
                  isSited,
                ),
              ),
            )
          : getRebaScore(
              getRebaResult(
                extractRebaData(
                  getRebaQuestions(),
                  frameAngles,
                  selectedAngles3D?.[index],
                  isSited,
                ),
              ),
            ),
      seconds: twoDecimalPlaces(index / fps),
    }));
  }, [selectedMethod, selectedAngles, selectedAngles3D, fps, isSited]);

  const handleUseSelectedFrame = useCallback(
    (pid: number | undefined) => {
      if (!frameIndex) return;

      const assessmentData =
        selectedMethod === "REBA"
          ? {
              reba: getRebaResult(
                extractRebaData(
                  getRebaQuestions(),
                  selectedAngles[frameIndex],
                  selectedAngles3D?.[frameIndex],
                  isSited,
                ),
              ),
            }
          : selectedMethod === "RULA"
            ? {
                rula: getRulaResult(
                  extractRulaData(
                    getRulaQuestions(),
                    selectedAngles[frameIndex],
                    selectedAngles3D?.[frameIndex],
                    isSited,
                  ),
                ),
              }
            : selectedMethod === "ERA"
              ? {
                  era: calculateEraData(
                    fps,
                    selectedAngles,
                    selectedAngles3D,
                    isSited,
                  ),
                }
              : {};

      setAssessmentDataDto(assessmentData);
      setAssessmentPageIndex(2);
      setTargetId(pid);
    },
    [
      fps,
      frameIndex,
      selectedMethod,
      selectedAngles,
      selectedAngles3D,
      isSited,
      setAssessmentDataDto,
      setAssessmentPageIndex,
      setTargetId,
    ],
  );

  return { getChartData, handleUseSelectedFrame };
};
