import { setupPdfDocument } from "./setupPdf";
import { generateJobInfo } from "./generateJobInfo";
import { generateMainTable } from "./main-table";
import { generateAccelerators } from "./generateAccelerators";
import { generateRiskRating } from "./generateRiskRating";
import { generateOverallScore } from "./generateOverallScore";
import { calculateEraScore } from "../score";
import { EraDto } from "src/app/services/generatedApi";

interface TablePosition {
  finalY: number;
}

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => TablePosition;
    lastAutoTable: TablePosition;
  }
}

export const generateEraReport = (eraDto: EraDto) => {
  const scores = calculateEraScore(eraDto);
  let doc = setupPdfDocument();

  doc = generateJobInfo(doc, eraDto);
  doc = generateMainTable(doc, eraDto);
  doc = generateAccelerators(doc, eraDto);
  doc = generateRiskRating(doc);
  doc = generateOverallScore(doc, scores);

  return doc;
};
