import { FC } from "react";
import { Box, Chip } from "@mui/material";
import { BusinessCenter, Person } from "@mui/icons-material";
import {
  UserRo,
  useUsersControllerSearchUsersQuery,
  useUsersControllerUpdateExistingUserMutation,
} from "src/app/services/generatedApi";
import { CompanyRo } from "src/app/services/generatedApi";

interface CompanyMembersCellProps {
  company: CompanyRo;
}

export const CompanyMembersCell: FC<CompanyMembersCellProps> = ({
  company,
}) => {
  const { data: usersData, refetch } = useUsersControllerSearchUsersQuery({
    companyId: company.id,
  });

  const [updateUser, { isLoading: isUpdating }] =
    useUsersControllerUpdateExistingUserMutation();

  const handleToggleManager = async (user: UserRo) => {
    try {
      await updateUser({
        userUpdateDto: {
          username: user.username,
          isManager: !user.isManager,
        },
      }).unwrap();
      refetch();
    } catch (error) {
      console.error("Error toggling manager:", error);
    }
  };

  const handleRemoveFromCompany = async (user: UserRo) => {
    try {
      await updateUser({
        userUpdateDto: {
          username: user.username,
          companyId: "",
        },
      }).unwrap();
      refetch();
    } catch (error) {
      console.error("Error removing user from company:", error);
    }
  };

  if (!usersData || !usersData.users) {
    return <span>No members</span>;
  }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {usersData.users.map((member) => (
        <Chip
          key={member.username}
          icon={member.isManager ? <BusinessCenter /> : <Person />}
          label={member.username}
          onClick={() => handleToggleManager(member)}
          onDelete={() => handleRemoveFromCompany(member)}
          disabled={isUpdating}
          variant="outlined"
        />
      ))}
    </Box>
  );
};
