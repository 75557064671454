import { FC } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CompanyCreateDto } from "src/app/services/generatedApi";

interface CompanyFormProps {
  initialValues: CompanyCreateDto;
  onSubmit: (values: CompanyCreateDto) => void;
  onCancel: () => void;
  submitLabel?: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email"),
  phone: Yup.string(),
  address: Yup.string(),
  status: Yup.mixed<"active" | "inactive">()
    .oneOf(["active", "inactive"])
    .required("Status is required"),
  employeeCount: Yup.number().typeError("Must be a number").nullable(),
  hasEraAccess: Yup.boolean(),
});

export const CompanyForm: FC<CompanyFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  submitLabel = "Submit",
}) => {
  const formik = useFormik<CompanyCreateDto>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex" flexDirection="column" gap={2} mt={2}>
        <TextField
          name="name"
          label="Name"
          fullWidth
          required
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.name && formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={formik.values.email || ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.email && formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          name="phone"
          label="Phone"
          fullWidth
          value={formik.values.phone || ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.phone && formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
        <TextField
          name="address"
          label="Address"
          fullWidth
          multiline
          rows={2}
          value={formik.values.address || ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.address && formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
        />
        <TextField
          name="employeeCount"
          label="Employee Count"
          type="number"
          fullWidth
          value={formik.values.employeeCount ?? ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik.touched.employeeCount && formik.errors.employeeCount,
          )}
          helperText={
            formik.touched.employeeCount && formik.errors.employeeCount
          }
        />

        <FormControl
          fullWidth
          error={Boolean(formik.touched.status && formik.errors.status)}
        >
          <InputLabel>Status</InputLabel>
          <Select
            name="status"
            label="Status"
            value={formik.values.status}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              name="hasEraAccess"
              checked={formik.values.hasEraAccess || false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          }
          label="Enable ERA Assessment Access"
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button onClick={onCancel} color="inherit" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!formik.isValid || formik.isSubmitting}
        >
          {submitLabel}
        </Button>
      </Box>
    </form>
  );
};
