import { FC } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { CompanyForm } from "./CompanyForm";
import { toast } from "react-toastify";
import {
  CompanyCreateDto,
  CompanyRo,
  useCompanyControllerUpdateMutation,
} from "src/app/services/generatedApi";

interface EditCompanyDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  company: CompanyRo | null;
}

const cleanFormData = (data: any): any => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value === "" ? undefined : value,
    ]),
  );
};

export const EditCompanyDialog: FC<EditCompanyDialogProps> = ({
  open,
  onClose,
  onSuccess,
  company,
}) => {
  const [updateCompany] = useCompanyControllerUpdateMutation();

  const getEditInitialValues = (company: CompanyRo): CompanyCreateDto => ({
    name: company.name,
    address: company.address || "",
    email: company.email || "",
    phone: company.phone || "",
    status: company.status,
    employeeCount: company.employeeCount ?? 0,
    hasEraAccess: company.hasEraAccess || false,
  });

  const handleSubmit = async (values: CompanyCreateDto) => {
    if (!company) return;
    try {
      const cleanedData = cleanFormData(values);
      await updateCompany({
        companyId: company.id,
        companyUpdateDto: cleanedData,
      }).unwrap();
      toast.success("Company updated successfully");
      onSuccess();
      onClose();
    } catch (error) {
      toast.error("Failed to update company");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Company</DialogTitle>
      <DialogContent>
        {company && (
          <CompanyForm
            initialValues={getEditInitialValues(company)}
            onSubmit={handleSubmit}
            onCancel={onClose}
            submitLabel="Save Changes"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
