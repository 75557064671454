import { EraDto } from "src/app/services/generatedApi";
import { boolToScore } from "./utils";

export function buildForceRow(eraDto: EraDto) {
  return [
    { content: "Force" },
    { content: ">10 lb" },
    { content: boolToScore(eraDto.back.force.over10lb, 2) },
    { content: "N/A" },
    { content: "-" },
    { content: ">10 lb 1-hand\n>15 lb 2-hand" },
    {
      content: [
        boolToScore(eraDto.leftShoulder.force.over10lb1Hand, 2),
        boolToScore(eraDto.leftShoulder.force.over15lb2Hand, 2),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightShoulder.force.over10lb1Hand, 2),
        boolToScore(eraDto.rightShoulder.force.over15lb2Hand, 2),
      ].join("\n"),
    },
    { content: ">10 lb 1-hand\n>15 lb 2-hand" },
    {
      content: [
        boolToScore(eraDto.leftElbow.force.over10lb1Hand, 2),
        boolToScore(eraDto.leftElbow.force.over15lb2Hand, 2),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightElbow.force.over10lb1Hand, 2),
        boolToScore(eraDto.rightElbow.force.over15lb2Hand, 2),
      ].join("\n"),
    },
    { content: "Pinch Grip>2 lb\nPower Grip>10 lb" },
    {
      content: [
        boolToScore(eraDto.leftWrist.force.pinchGripOver2lb, 2),
        boolToScore(eraDto.leftWrist.force.powerGripOver10lb, 2),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightWrist.force.pinchGripOver2lb, 2),
        boolToScore(eraDto.rightWrist.force.powerGripOver10lb, 2),
      ].join("\n"),
    },
  ];
}
