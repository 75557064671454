import { FC } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "src/pages/Login";
import PrivateRoute from "./PrivateRoute";
import AdminPrivateRoute from "./AdminPrivateRoute";
import NotFound from "src/pages/NotFound";
import AddVideo from "src/pages/AddVideo";
import Tasks from "src/pages/Tasks";
import Task from "src/pages/Task";
import Profile from "src/pages/Profile";
import Compare from "src/pages/Compare";
import ForgotPassword from "src/pages/ForgotPassword";
import AllTasks from "src/pages/admin/AllTasks";
import Users from "src/pages/admin/Users";
import Companies from "src/pages/admin/Companies";
import AngleThresholds from "src/pages/AngleThresholds";

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword/*" element={<ForgotPassword />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<AddVideo />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/results" element={<Tasks />} />
          <Route path="/result" element={<Navigate to="/results" />} />
          <Route path="/result/:id" element={<Task />} />
          <Route path="/compare/:id1/:id2" element={<Compare />} />
          <Route path="/angleThresholds" element={<AngleThresholds />} />

          <Route element={<AdminPrivateRoute />}>
            <Route path="/users" element={<Users />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/allTasks" element={<AllTasks />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
