import { FC } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { CompanyForm } from "./CompanyForm";
import { toast } from "react-toastify";
import {
  CompanyCreateDto,
  useCompanyControllerCreateMutation,
} from "src/app/services/generatedApi";

interface AddCompanyDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const initialFormData: CompanyCreateDto = {
  name: "",
  status: "active",
  employeeCount: 0,
  hasEraAccess: false,
};

const cleanFormData = (data: any): any => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value === "" ? undefined : value,
    ]),
  );
};

export const AddCompanyDialog: FC<AddCompanyDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [createCompany] = useCompanyControllerCreateMutation();

  const handleSubmit = async (values: CompanyCreateDto) => {
    try {
      const cleanedData = cleanFormData(values);
      await createCompany({ companyCreateDto: cleanedData }).unwrap();
      toast.success("Company created successfully");
      onSuccess();
      onClose();
    } catch (error) {
      toast.error("Failed to create company");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Company</DialogTitle>
      <DialogContent>
        <CompanyForm
          initialValues={initialFormData}
          onSubmit={handleSubmit}
          onCancel={onClose}
          submitLabel="Add"
        />
      </DialogContent>
    </Dialog>
  );
};
