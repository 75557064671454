import { EraDto } from "src/app/services/generatedApi";
import { boolToScore } from "./utils";

export function buildSevereRow(eraDto: EraDto) {
  return [
    { content: "Severe\nPosture" },
    { content: "Flexed>45°\nExtended>20°" },
    {
      content: [
        boolToScore(eraDto.back.severePosture.flexedOver45),
        boolToScore(eraDto.back.severePosture.extendedOver20),
      ].join("\n"),
    },
    { content: "Flexed>60°\nExtended>30°" },
    {
      content: [
        boolToScore(eraDto.neck.severePosture.flexedOver60),
        boolToScore(eraDto.neck.severePosture.extendedOver30),
      ].join("\n"),
    },
    { content: "Flexed>90°\nAbducted>90°" },
    {
      content: [
        boolToScore(eraDto.leftShoulder.severePosture.flexedOver90),
        boolToScore(eraDto.leftShoulder.severePosture.abductedOver90),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightShoulder.severePosture.flexedOver90),
        boolToScore(eraDto.rightShoulder.severePosture.abductedOver90),
      ].join("\n"),
    },
    { content: "Forearm\nRotated>60°" },
    {
      content: boolToScore(eraDto.leftElbow.severePosture.forearmRotatedOver60),
    },
    {
      content: boolToScore(
        eraDto.rightElbow.severePosture.forearmRotatedOver60,
      ),
    },
    { content: "Flexed>65°\nExtended>65°" },
    {
      content: [
        boolToScore(eraDto.leftWrist.severePosture.flexedOver65),
        boolToScore(eraDto.leftWrist.severePosture.extendedOver65),
      ].join("\n"),
    },
    {
      content: [
        boolToScore(eraDto.rightWrist.severePosture.flexedOver65),
        boolToScore(eraDto.rightWrist.severePosture.extendedOver65),
      ].join("\n"),
    },
  ];
}
