import { EraDto } from "src/app/services/generatedApi";
import { buildMainTableHead } from "./buildMainTableHead";
import { buildPostureRow } from "./buildPostureRow";
import { buildSevereRow } from "./buildSevereRow";
import { buildForceRow } from "./buildForceRow";
import { buildRepetitionRow } from "./buildRepetitionRow";
import { buildStaticRow } from "./buildStaticRow";
import { buildTotalRow } from "./buildTotalRow";
import { calculateEraScore } from "../../score";

export function buildMainTableData(eraDto: EraDto) {
  return {
    mainTableHead: buildMainTableHead(),
    mainTableBody: [
      buildPostureRow(eraDto),
      buildSevereRow(eraDto),
      buildForceRow(eraDto),
      buildRepetitionRow(eraDto),
      buildStaticRow(eraDto),
      buildTotalRow(calculateEraScore(eraDto)),
    ],
  };
}
