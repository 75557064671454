import { FC, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Check,
} from "@mui/icons-material";
import { CompanyRo } from "src/app/services/generatedApi";
import { CompanyMembersCell } from "./CompanyMembersCell";
import { DeleteCompanyDialog } from "./DeleteCompanyDialog";
import { toast } from "react-toastify";
import { useCompanyControllerRemoveMutation } from "src/app/services/generatedApi";

interface CompanyTableProps {
  companies: CompanyRo[];
  onEditClick: (company: CompanyRo) => void;
  onDeleteSuccess: () => void;
}

export const CompanyTable: FC<CompanyTableProps> = ({
  companies,
  onEditClick,
  onDeleteSuccess,
}) => {
  const [deleteCompany] = useCompanyControllerRemoveMutation();
  const [companyToDelete, setCompanyToDelete] = useState<CompanyRo | null>(
    null,
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteClick = (company: CompanyRo) => {
    setCompanyToDelete(company);
  };

  const handleDeleteConfirm = async () => {
    if (!companyToDelete) return;

    setIsDeleting(true);
    try {
      await deleteCompany({ companyId: companyToDelete.id }).unwrap();
      toast.success("Company deleted successfully");
      onDeleteSuccess();
    } catch (error) {
      toast.error("Failed to delete company");
    } finally {
      setIsDeleting(false);
      setCompanyToDelete(null);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Employees</TableCell>
              <TableCell>Members</TableCell>
              <TableCell>Era</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow key={company.id}>
                <TableCell>{company.name}</TableCell>
                <TableCell>
                  <Chip
                    label={company.status}
                    color={company.status === "active" ? "success" : "error"}
                    size="small"
                  />
                </TableCell>
                <TableCell>{company.email}</TableCell>
                <TableCell>{company.phone}</TableCell>
                <TableCell>{company.employeeCount}</TableCell>
                <TableCell>
                  <CompanyMembersCell company={company} />
                </TableCell>
                <TableCell>{company.hasEraAccess ? <Check /> : "-"}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => onEditClick(company)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteClick(company)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteCompanyDialog
        open={!!companyToDelete}
        onClose={() => setCompanyToDelete(null)}
        onConfirm={handleDeleteConfirm}
        companyName={companyToDelete?.name ?? ""}
        isDeleting={isDeleting}
      />
    </>
  );
};
