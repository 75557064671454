import { EraScore } from "../../score";

export function buildTotalRow(scores: EraScore) {
  return [
    { content: "TOTAL" },
    { content: "" },
    { content: scores.backScore.toString() },
    { content: "" },
    { content: scores.neckScore.toString() },
    { content: "" },
    { content: scores.leftShoulderScore.toString() },
    { content: scores.rightShoulderScore.toString() },
    { content: "" },
    { content: scores.leftElbowScore.toString() },
    { content: scores.rightElbowScore.toString() },
    { content: "" },
    { content: scores.leftWristScore.toString() },
    { content: scores.rightWristScore.toString() },
  ];
}
