import { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface DeleteCompanyDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  companyName: string;
  isDeleting: boolean;
}

export const DeleteCompanyDialog: FC<DeleteCompanyDialogProps> = ({
  open,
  onClose,
  onConfirm,
  companyName,
  isDeleting,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Company</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the company "{companyName}"? This
          action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit" disabled={isDeleting}>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          variant="contained"
          disabled={isDeleting}
        >
          {isDeleting ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
